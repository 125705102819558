import React from "react";
import ReactPlayer from 'react-player/lazy'

const GalleryCard = ({ item }) => {
    return (
        <div className="col-lg-4 col-sm-6">
            <div className="gallery-item">
                <ReactPlayer controls={true} height={280} width={360} url={item.SettingsValuesDescription} />
                <h6 class="mt-3" style={{ textAlign: 'center' }}>
                    {item.SettingsValuesTitle}
              </h6>
            </div>
        </div>
    );
};

export default GalleryCard;
