import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import VideoGallery from "../../components/gallery/video-gallery-page";

const GalleryPage = () => {
    return (
        <Layout pageTitle="Jci India Foundation | Video Gallery">
            <NavOne />
            <PageHeader title="Video Gallery" />
            <VideoGallery />
            <Footer />
        </Layout>
    );
};

export default GalleryPage;
