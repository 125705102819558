import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContentLoader from "react-content-loader";

const Loader = (props) => (
    <div style={{ width: "100%", height: "100%" }}>
        <Row>
            <Col xl={12} lg={12} >
                <ContentLoader
                    speed={2}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <rect x="25" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="100" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="175" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="250" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="325" y="15" rx="5" ry="5" width="50" height="50" />
                    <rect x="25" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="100" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="175" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="250" y="90" rx="5" ry="5" width="50" height="50" />
                    <rect x="325" y="90" rx="5" ry="5" width="50" height="50" />
                </ContentLoader>
            </Col>
        </Row>
    </div >
)

export default Loader;