import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const NoRecords = () => (
    <div style={{ width: "100%", height: "100%" }}>
        <Row>
            <Col xl={12} lg={12} >
                <p class="text-center">No Records Found</p>
            </Col>
        </Row>
    </div >
)

export default NoRecords;